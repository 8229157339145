import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "controllers"

import "tailwindcss/tailwind.css"
import "bootstrap-icons/font/bootstrap-icons.css"
import "stylesheets/application.css"

Rails.start()
ActiveStorage.start()

import "images/favicon.ico"
